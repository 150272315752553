import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Grid, Center,
  theme, SimpleGrid, useColorModeValue, useColorMode,
  HStack, Flex, Spacer, Image
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import "nes.css/css/nes.min.css";
import logo from './images/CharmLogo01.png'
import sparta from './images/sparta.png'
import textLogo from './images/title.png';
import CharQ1 from './images/CharQuestion01.png';
import CharIdea01 from './images/CharIdea01.png';
import CharLambda from './images/CharLambda.png';
import CharSwap from './images/CharSwap.png';
import GitbookLogo from './images/gitbook-logo.png';
import DiscordLogo from './images/discord_r.png';
import TwitterLogo from './images/twitter.png';

function App() {

  // bg="#6bd4f9"
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl" bg='#6bd4f9'>
        <Grid minH="100vh" bg='#6bd4f9' >
          <Flex alignContent='center'>
            <Image
              boxSize="50px"
              src={logo}
              alt="Spartacadabra"
              p={2}
            />
          </Flex>

          <VStack spacing={10} m4='-20px'>
            <Image
              objectFit="contain"
              boxSize={['80%', '60%', "40%"]}
              src={textLogo}
            />
            <Box fontSize={['md', 'md', '2xl']}>
              <div className="nes-container is-centered is-rounded">
                <p>Spartacadabra unlocks yield bearing assets  </p>
                <p>and conjures magic stable coin Lambda (λ).</p>
              </div>

            </Box>

            {/* <Text fontSize='sm'>Spartacadabra app launch on Monday, Dec 06 2021, 12PM UTC</Text> */}
            <SimpleGrid columns={3} spacing={10} fontSize={['md', 'md', 'large']}>
              <Link href='https://app.spartacadabra.money/' isExternal>
                <button type="button" className="nes-btn is-warning">Enter App</button>
              </Link>

              <Link href='https://spookyswap.finance/swap?inputCurrency=0x04068da6c83afcfa0e13ba15a6696662335d5b75&outputCurrency=0x248CB87DDA803028dfeaD98101C9465A2fbdA0d4' isExternal>
                <button type="button" className="nes-btn is-error">Get Charm </button>
              </Link>
              <Link href='https://docs.spartacadabra.money/' isExternal>
                <button type="button" className="nes-btn is-primary">Read Docs</button>
              </Link>
            </SimpleGrid>
            <Spacer />

          </VStack>
          <Flex flexDirection='column'>
            {/* <SimpleGrid columns={3} spacing={20} alignSelf='center'> */}
            <Spacer />
            <SimpleGrid columns={3} spacing={15} alignSelf='center'>
              <Image
                objectFit="contain"
                boxSize="100%"
                src={sparta}
                alt="sparta"
                _hover={{
                  transform: 'translateY(-10px)',
                  transition: 'transform 0.2s ease 0s'
                }}
              />
              <Box _hover={{
                transform: 'translateY(-10px)',
                transition: 'transform 0.2s ease 0s'
              }}>
                <i className="nes-icon coin is-large"></i>
              </Box>
              <Box _hover={{
                transform: 'translateY(-10px)',
                transition: 'transform 0.2s ease 0s'
              }}><i className="nes-icon coin is-large"></i>
              </Box>
            </SimpleGrid>

            {/* <Logo /> */}
            {/* </SimpleGrid> */}
            {/* <Image
              objectFit="contain"
              boxSize="100%"
              src={RunWay}

            /> */}
            <progress className="nes-progress is-pattern" value="100" max="100"></progress>
            <Spacer />
          </Flex>
        </Grid>


        <Grid minH="84vh" fontSize={['md', 'md', 'large']}>
          {/* bg='6bd4f9' */}
          <Flex flexDirection='row' justifySelf='center' >
            <Spacer />

            <section className="message-list">
              <SimpleGrid column={1} spacing={10} justifyItems='center'>
                <Box mt='5'>
                  <section className="message -left">
                    <HStack>
                      <Image
                        objectFit="contain"
                        boxSize="22%"
                        src={CharQ1}
                      />
                      <div className="nes-balloon from-left">
                        <p>How does it work?</p>
                      </div>
                    </HStack>
                  </section>
                </Box>

                <Box>
                  <section className="message -right">
                    <HStack>
                      <div className="nes-balloon from-right">
                        <p>1. Provide yield bearing crypto assets as collateral.</p>
                      </div>
                      <Image
                        objectFit="contain"
                        boxSize={['30%', '15%', "14%"]}
                        src={CharIdea01}
                      />
                    </HStack>
                  </section>
                </Box>

                <Box ml={['0px', '20px', '100px']}>
                  <section className="message -left">
                    <HStack>
                      <Image
                        objectFit="contain"
                        boxSize={['30%', '20%', "14%"]}
                        src={CharLambda}
                      />
                      <div className="nes-balloon from-left">
                        <p>2. Borrow magic stable coin Lambda (λ).</p>
                      </div>
                    </HStack>
                  </section>
                </Box>

                <Box mt='5'>
                  <section className="message -right">
                    <HStack>
                      <div className="nes-balloon from-right">
                        <p>3. Swap Lambda (λ) to other crypto assets.</p>
                      </div>
                      <Image
                        objectFit="contain"
                        boxSize={['30%', '15%', "14%"]}
                        src={CharSwap}
                      />
                    </HStack>
                  </section>

                </Box>
                <Spacer />

              </SimpleGrid>
            </section >


          </Flex>

          <progress className="nes-progress is-pattern" value="100" max="100"></progress>
        </Grid >
        <Grid minH="10vh" bg='#e36151'>
          <VStack mt='3'>
            <SimpleGrid columns={3} spacing={10}>
              <Link href='https://twitter.com/spartacadabra' title='twitter' isExternal><Image
                boxSize="40px"
                src={TwitterLogo}
                alt="twitter"
              /></Link>
              <Link href='https://discord.gg/kFfrK7CdKc' title='discord' isExternal>
                <Image
                  boxSize="40px"
                  src={DiscordLogo}
                  alt="discord"
                />
              </Link>
              <Link href="https://docs.spartacadabra.money" title='gitbook' isExternal>
                <Image
                  objectFit='contain'
                  boxSize="40px"
                  src={GitbookLogo}
                  alt="gitbook"
                />
              </Link>

            </SimpleGrid>

            <Center>
              <i class="nes-icon is-small heart"></i>
              <Link href='https://app.spartacus.finance' isExternal>
                <Text fontSize='sm'>Born out of Spartacus DAO community</Text>
              </Link>
            </Center>

          </VStack>
        </Grid>

      </Box >
    </ChakraProvider >
  );
}

export default App;
